import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { IntegrationCard } from "src/components/Integrations/IntegrationCard"
import {
  TIntegration,
  TIntegrationStatic,
} from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import SearchIcon from "src/ui/icons/search-no-results.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function IntegrationsListContents({
  integrations,
  loading,
  error,
}: {
  integrations: (TIntegration | TIntegrationStatic)[]
  loading: boolean
  error: boolean
}) {
  const { t, langKeys } = useTranslate()

  if (loading) {
    return <IntegrationsLoading />
  }

  if (error) {
    return (
      <MBanner type="error" fullWidth>
        {t(langKeys.failed_general_error_try_refreshing_page)}
      </MBanner>
    )
  }

  if (integrations.length <= 0) {
    return (
      <EmptyState
        icon={
          <SearchIconBox>
            <SearchIcon width={60} height={60} />
          </SearchIconBox>
        }
        title={t(langKeys.search_nothing_found)}
      />
    )
  }

  return (
    <IntegrationsListGrid>
      {integrations?.map((i) => (
        <IntegrationCard key={i.integration_identifier} integration={i} />
      ))}
    </IntegrationsListGrid>
  )
}

function IntegrationsLoading() {
  return (
    <IntegrationsListGrid>
      {Array.from(Array(10), (_, index) => {
        return (
          <SkeletonBox key={`skeleton-${index}`}>
            <MSkeleton
              key={`skeleton-icon-${index}`}
              variant="rect"
              width={56}
              height={56}
            />
            <MSkeleton
              key={`skeleton-text-${index}`}
              variant="rect"
              width={`${() => Math.floor(Math.random() * 10 + 9)}ch`}
            />
          </SkeletonBox>
        )
      })}
    </IntegrationsListGrid>
  )
}

const SkeletonBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: ${spacing.M};
`

const SearchIconBox = styled.div`
  background-color: ${mColors.accentPrimaryLight};
  border-radius: 50%;
  padding: ${spacing.L};
`

const IntegrationsListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${spacing.XL};
  align-items: center;
`
