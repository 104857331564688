import { usePostTrackEventWithParams } from "./analyticsQueries"

type TCsvImporterEvents = {
  "Stay Import File Uploaded": Record<string, never>
  "Stay Importer Viewed": Record<string, never>
  "Stay Import Initiated": Record<string, never>
  "Stay Import Completed": {
    imported_stays: number
    import_errors: number
    success: boolean
  }
}

export function usePostTrackCsvStayImporterEvent<
  K extends keyof TCsvImporterEvents,
>(event: K) {
  return usePostTrackEventWithParams<K, TCsvImporterEvents[K]>({
    event,
  })
}
