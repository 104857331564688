import { useEffect } from "react"

import { useDebouncedCallback } from "use-debounce"

import {
  usePostIntegrationFilterAppliedEvent,
  usePostIntegrationFiltersViewedEvent,
  usePostIntegrationListViewEvent,
  usePostIntegrationSearchedEvent,
  usePostIntegrationViewedEvent,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import { useFetchMultipleIntegrationLinks } from "src/data/integrations/queries/integrationLinkQueries"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import {
  TIntegration,
  TIntegrationFilter,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"

function useGetIntegrationHomeLinksCount({
  configuredIntegrations,
  isFetched,
}: {
  configuredIntegrations: TIntegration[]
  isFetched: boolean
}) {
  const { orgId } = useOrganization()

  const fetchIntegrationHomeLinksCount = useFetchMultipleIntegrationLinks(
    {
      integrationIds: configuredIntegrations
        .filter((i) => i.type !== "automation")
        .map((i) => i.integration_identifier),
      orgId,
    },

    {
      enabled: isFetched,
      select(data) {
        const transformedIntegrationLinks: Record<string, unknown> = {}

        for (const i of data) {
          transformedIntegrationLinks[i.id] = {
            linked_homes: i.links.length || 0,
          }
        }

        return transformedIntegrationLinks
      },
    }
  )

  return fetchIntegrationHomeLinksCount
}

export function useSendIntegrationTrackEvents({
  configuredIntegrations,
  isFetched,
}: {
  configuredIntegrations: TIntegration[]
  isFetched: boolean
}) {
  const fetchIntegrationHomeLinksCount = useGetIntegrationHomeLinksCount({
    configuredIntegrations,
    isFetched,
  })

  const { mutate: mutateIntegrationsListViewEvent } =
    usePostIntegrationListViewEvent()

  useEffect(() => {
    if (fetchIntegrationHomeLinksCount.isLoading) {
      return
    }

    mutateIntegrationsListViewEvent({
      integrations: fetchIntegrationHomeLinksCount.data || {},
    })
  }, [
    fetchIntegrationHomeLinksCount.data,
    fetchIntegrationHomeLinksCount.isLoading,
    mutateIntegrationsListViewEvent,
  ])
}

export function useTrackIntegrationSearchedEvent({
  configuredIntegrations,
  isFetched,
}: {
  configuredIntegrations: TIntegration[]
  isFetched: boolean
}) {
  const postIntegrationSearchedEvent = usePostIntegrationSearchedEvent()

  const fetchIntegrationHomeLinksCount = useGetIntegrationHomeLinksCount({
    configuredIntegrations,
    isFetched,
  })

  const sendIntegrationEventDebounced = useDebouncedCallback(() => {
    postIntegrationSearchedEvent.mutate({
      integrations: fetchIntegrationHomeLinksCount.data || {},
    })
  }, 1000)

  return { sendIntegrationEventDebounced }
}

export function useTrackIntegrationFiltersViewedEvent({
  configuredIntegrations,
  isFetched,
}: {
  configuredIntegrations: TIntegration[]
  isFetched: boolean
}) {
  const postIntegrationFiltersViewedEvent =
    usePostIntegrationFiltersViewedEvent()

  const fetchIntegrationHomeLinksCount = useGetIntegrationHomeLinksCount({
    configuredIntegrations,
    isFetched,
  })

  return {
    postIntegrationFiltersViewEvent: () =>
      postIntegrationFiltersViewedEvent.mutate({
        integrations: fetchIntegrationHomeLinksCount.data || {},
      }),
  }
}

export function useTrackIntegrationFilterAppliedEvent({
  configuredIntegrations,
  isFetched,
}: {
  configuredIntegrations: TIntegration[]
  isFetched: boolean
}) {
  const postIntegrationFilterApplied = usePostIntegrationFilterAppliedEvent()

  const fetchIntegrationHomeLinksCount = useGetIntegrationHomeLinksCount({
    configuredIntegrations,
    isFetched,
  })

  function postIntegrationFiltersAppliedEvent(
    activeFilters: TIntegrationFilter[]
  ) {
    const filterIds = activeFilters.map((f) => f.id)

    return postIntegrationFilterApplied.mutate({
      integration_category: filterIds,
      integrations: fetchIntegrationHomeLinksCount.data || {},
    })
  }

  return {
    postIntegrationFiltersAppliedEvent,
  }
}

export function useTrackIntegrationViewedEvent({
  integration,
  tabId,
}: {
  integration: TIntegration
  tabId: string
}) {
  const { orgId } = useOrganization()
  const fetchConfiguredIntegrations = useFetchIntegrations({
    orgId,
    filters: {
      configured: true,
      includeExternalIntegrations: true,
    },
  })

  const configuredIntegrations =
    fetchConfiguredIntegrations.data?.integrations || []

  const fetchIntegrationHomeLinksCount = useGetIntegrationHomeLinksCount({
    configuredIntegrations,
    isFetched: fetchConfiguredIntegrations.isFetched,
  })

  const { mutate: mutateIntegrationViewedEvent } =
    usePostIntegrationViewedEvent()

  useEffect(() => {
    if (fetchIntegrationHomeLinksCount.isLoading) {
      return
    }

    mutateIntegrationViewedEvent({
      integration_name: integration.name,
      integration_tab: tabId,
      integrations: fetchIntegrationHomeLinksCount.data || {},
    })
  }, [
    fetchIntegrationHomeLinksCount.data,
    fetchIntegrationHomeLinksCount.isLoading,
    integration.name,
    tabId,
    mutateIntegrationViewedEvent,
  ])
}
