import styled from "styled-components"

import { TSettings } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatSettingsDialog"
import { breakpoint } from "src/constants/breakpoints"
import { TThermostatMode } from "src/data/homes/types/homeTypes"
import { TTemperatureUnit } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { Container } from "src/ui/Container/Container"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { convertTemperatureValue } from "src/utils/l10n"

export function ThermostatTemperatureInput({
  selectedMode,
  temperatureControlValues,
  setTemperatureControlValues,
  temperatureUnit,
  temperatureDelta,
}: {
  selectedMode?: TThermostatMode
  temperatureControlValues: TSettings
  setTemperatureControlValues: (value: TSettings) => void
  temperatureUnit: NonNullable<TTemperatureUnit>
  temperatureDelta: number
}) {
  const { t, langKeys } = useTranslate()

  // The heat minmax(16,26) and cool minmax(20,30) are the only acceptable values for now
  const HEAT_TEMP_MIN = Number(convertTemperatureValue(16, temperatureUnit, 0))
  const HEAT_TEMP_MAX = Number(convertTemperatureValue(26, temperatureUnit, 0))
  const COOL_TEMP_MIN = Number(convertTemperatureValue(20, temperatureUnit, 0))
  const COOL_TEMP_MAX = Number(convertTemperatureValue(30, temperatureUnit, 0))

  const showHeatInput = selectedMode === "heatcool" || selectedMode === "heat"
  const showCoolInput = selectedMode === "heatcool" || selectedMode === "cool"

  function handleChangeHeatTemperature(value: number) {
    const desiredHeatTemp = clampTemperatureValue({
      value,
      maxTemp: HEAT_TEMP_MAX,
      minTemp: HEAT_TEMP_MIN,
    })

    const coolTemp = temperatureControlValues.coolTemperature
    const minAllowedCoolTemp = desiredHeatTemp + temperatureDelta

    const adjustedCoolTemp = Math.min(
      Math.max(coolTemp, minAllowedCoolTemp),
      COOL_TEMP_MAX
    )

    setTemperatureControlValues({
      ...temperatureControlValues,
      heatTemperature: desiredHeatTemp,
      coolTemperature: adjustedCoolTemp,
    })
  }

  function handleChangeCoolTemperature(value: number) {
    const desiredCoolTemp = clampTemperatureValue({
      value,
      maxTemp: COOL_TEMP_MAX,
      minTemp: COOL_TEMP_MIN,
    })

    const heatTemp = temperatureControlValues.heatTemperature
    const maxAllowedHeatTemp = desiredCoolTemp - temperatureDelta

    const adjustedHeatTemp = Math.max(
      Math.min(heatTemp, maxAllowedHeatTemp),
      HEAT_TEMP_MIN
    )

    setTemperatureControlValues({
      ...temperatureControlValues,
      coolTemperature: desiredCoolTemp,
      heatTemperature: adjustedHeatTemp,
    })
  }
  return (
    <Container>
      <TemperatureBox>
        {showHeatInput && (
          <MTextField
            type="number"
            min={HEAT_TEMP_MIN}
            max={HEAT_TEMP_MAX}
            label={
              selectedMode === "heatcool"
                ? `${t(langKeys.min_temperature)} (°${temperatureUnit})`
                : `${t(langKeys.temperature)} (°${temperatureUnit})`
            }
            value={temperatureControlValues.heatTemperature.toString()}
            onChange={(value) => handleChangeHeatTemperature(Number(value))}
            hidden={true}
          />
        )}

        {showCoolInput && (
          <MTextField
            type="number"
            min={COOL_TEMP_MIN}
            max={COOL_TEMP_MAX}
            label={
              selectedMode === "heatcool"
                ? `${t(langKeys.max_temperature)} (°${temperatureUnit})`
                : `${t(langKeys.temperature)} (°${temperatureUnit})`
            }
            value={temperatureControlValues.coolTemperature.toString()}
            onChange={(value) => handleChangeCoolTemperature(Number(value))}
          />
        )}
      </TemperatureBox>
    </Container>
  )
}

function clampTemperatureValue({
  value,
  maxTemp,
  minTemp,
}: {
  value: number
  maxTemp: number
  minTemp: number
}): number {
  if (value > maxTemp) {
    return maxTemp
  }

  if (value < minTemp) {
    return minTemp
  }

  return value
}

const TemperatureBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  flex-wrap: wrap;
  width: 100%;

  @container (${breakpoint.smallUp}) {
    flex-wrap: nowrap;
  }
`
