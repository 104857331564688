import styled from "styled-components"

import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { translateThermostatMode } from "src/data/integrations/logic/integrations"
import {
  TAvailableThermostatMode,
  TIntegrationHomeLink,
} from "src/data/integrations/types/integrationTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { TTemperatureUnit } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { formatTemperatureString } from "src/utils/l10n"

export function ThermostatConfiguration({
  hidden,
  commonAvailableModes,
  linked,
  link,
}: {
  hidden: boolean
  commonAvailableModes: TAvailableThermostatMode[]
  linked: boolean
  link: TIntegrationHomeLink
}) {
  const { t, langKeys } = useTranslate()

  const user = useGetUser()
  const unit = getTemperatureUnitWithFallback(user)

  const { heat_temperature, cool_temperature } =
    link?.thermostat_temperature_setpoint_on_checkin ?? {}

  const thermostatMode = link?.thermostat_mode_on_checkin

  const temperatureSetPoint = getTemperatureSetpoint({
    heatTemperature: heat_temperature,
    coolTemperature: cool_temperature,
    thermostatMode: thermostatMode,
    unit,
  })

  if (hidden) {
    return null
  }

  if (!commonAvailableModes || !linked) {
    return <div></div>
  }

  return (
    <Box>
      <PillsWrapper>
        <MBadge>{translateThermostatMode(thermostatMode, t)}</MBadge>

        {!!temperatureSetPoint && <MBadge>{temperatureSetPoint}</MBadge>}
      </PillsWrapper>

      {link?.thermostat_turn_on_in_minutes_before_checkin && (
        <div>
          <MBadge>
            {t(langKeys.integrations_time_before_stay_begins, {
              time: link?.thermostat_turn_on_in_minutes_before_checkin / 60,
            })}
          </MBadge>
        </div>
      )}
    </Box>
  )
}

function getTemperatureSetpoint({
  heatTemperature,
  coolTemperature,
  thermostatMode,
  unit,
}: {
  heatTemperature: number | undefined
  coolTemperature: number | undefined
  thermostatMode: TAvailableThermostatMode | undefined
  unit: TTemperatureUnit
}) {
  if (thermostatMode === "heat" && !!heatTemperature) {
    return formatTemperatureString(heatTemperature, unit, 0)
  }

  if (thermostatMode === "cool" && !!coolTemperature) {
    return formatTemperatureString(coolTemperature, unit, 0)
  }

  if (thermostatMode === "heatcool" && !!heatTemperature && !!coolTemperature) {
    return `${formatTemperatureString(heatTemperature, unit, 0)} -
      ${formatTemperatureString(coolTemperature, unit, 0)}`
  }

  return null
}

const Box = styled.div`
  display: grid;
  justify-content: start;
  gap: ${spacing.XS};
`
