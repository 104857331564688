import { Dispatch, Fragment, SetStateAction, useState } from "react"
import styled from "styled-components"

import { ListItemIcon, ListItemText } from "@material-ui/core"

import { ConfirmDeleteCredentialDialog } from "src/components/Integrations/Integration/ConfirmDeleteCredentialDialog"
import { Pager } from "src/components/Pager/Pager"
import {
  useConnectOauth,
  useDeleteCredentialMutation,
  useFetchActiveCredentials,
} from "src/data/integrations/queries/integrationAuthQueries"
import { useFetchIntegrationLinks } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
  TntegrationAuthCredential,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { brandText } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import DisconnectIcon from "src/ui/icons/unlink.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MultiAccountConnectView({
  integration,
  setStartAuthFlow: startAuthFlow,
}: {
  integration: TIntegration
  setStartAuthFlow: Dispatch<SetStateAction<boolean>>
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const connectOauth = useConnectOauth({ ownerType: OwnerType.ORGANIZATION })
  const limit = 10
  const [offset, setOffset] = useState(0)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [credentialToBeDeleted, setCredentialToBeDeleted] =
    useState<TntegrationAuthCredential>()

  const fetchActiveCredentials = useFetchActiveCredentials({
    ownerId: orgId,
    ownerType: OwnerType.ORGANIZATION,
    integration: integration.integration_identifier,
    filters: { limit, offset },
  })

  const deleteCredentialMutation = useDeleteCredentialMutation()

  const fetchIntegrationLinks = useFetchIntegrationLinks(
    {
      integration: integration.integration_identifier,
      owner_type: OwnerType.ORGANIZATION,
      owner_id: orgId,
      account_id: credentialToBeDeleted?.account_id,
    },
    {
      enabled: !!credentialToBeDeleted,
    }
  )

  const headers = [
    <div key={"account-name"}>{t(langKeys.integrations_account_name)}</div>,
    <div key={"email"}>{t(langKeys.email)}</div>,
    <div key={"misc"}></div>,
  ]

  function handleDialogClose() {
    setCredentialToBeDeleted(undefined)
    setShowConfirmDelete(false)
  }

  function handleDeleteCredential() {
    if (credentialToBeDeleted) {
      deleteCredentialMutation.mutate(
        {
          ownerId: orgId,
          ownerType: OwnerType.ORGANIZATION,
          integration: integration.integration_identifier,
          integrationAccountId: credentialToBeDeleted?.account_id,
        },
        {
          onSuccess: () => {
            setOffset(0)
            handleDialogClose()
          },
        }
      )
    }
  }

  const rows =
    fetchActiveCredentials.data?.credentials?.map((credential) => {
      return (
        <Fragment key={credential.account_id}>
          <div>{credential.account_name}</div>
          <div>{credential.email}</div>
          <div>
            <MoreButton
              menuItems={[
                {
                  key: "disconnect-account",
                  contents: (
                    <>
                      <ListItemIcon>
                        <DisconnectIcon
                          height="24"
                          width="24"
                          color={brandText}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t(langKeys.integrations_disconnect_account)}
                      />
                    </>
                  ),
                  onClick() {
                    setCredentialToBeDeleted(credential)
                    setShowConfirmDelete(true)
                  },
                },
              ]}
              disabled={deleteCredentialMutation.isLoading}
            />
          </div>
        </Fragment>
      )
    }) ?? createSkeletonRows()

  return (
    <div>
      <TitleBox>
        <TextBox>
          <MText variant="heading3">
            {t(langKeys.integrations_linked_accounts_title)}
          </MText>
          <MText>
            {t(langKeys.integrations_linked_accounts_description, {
              name: integration.name,
            })}
          </MText>
          <MText> </MText>
          <MText>
            {t(langKeys.integrations_linked_accounts_add_instructions, {
              name: integration.name,
            })}
          </MText>
        </TextBox>
        <ButtonBox>
          <MButton
            onClick={() => startAuthFlow(true)}
            loading={connectOauth.isLoading}
            variant="secondary"
          >
            {t(langKeys.integrations_link_account)}
          </MButton>
        </ButtonBox>
      </TitleBox>

      <GridTable
        header={headers}
        rows={rows}
        templateColumns={`minmax(150px, 1fr) repeat(${
          headers.length - 1
        }, auto)`}
        useFallbackResponsiveMode
      />
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchActiveCredentials?.data?.paging?.total_count}
        />
      </PagerWrapper>
      {credentialToBeDeleted && (
        <ConfirmDeleteCredentialDialog
          showConfirmDelete={showConfirmDelete}
          onClose={handleDialogClose}
          handleDeleteCredential={handleDeleteCredential}
          credential={credentialToBeDeleted}
          nbrLinks={fetchIntegrationLinks.data?.links?.length}
          loading={deleteCredentialMutation.isLoading}
        />
      )}
    </div>
  )
}

function createSkeletonRows() {
  return [
    <Fragment key={Math.random()}>
      <div>
        <MSkeleton />
      </div>
      <div>
        <MSkeleton />
      </div>
    </Fragment>,
    <Fragment key={Math.random()}>
      <div>
        <MSkeleton />
      </div>
      <div>
        <MSkeleton />
      </div>
    </Fragment>,
  ]
}

const TextBox = styled.div`
  display: grid;
  gap: ${spacing.S};
`

const TitleBox = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: ${spacing.XL2} 0;
  align-items: flex-start;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
