import styled from "styled-components"

import {
  TIntegration,
  TIntegrationId,
} from "src/data/integrations/types/integrationTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"

export function BeforeYouContinueDialog({
  name,
  integrationId,
  open,
  onClose,
  onConfirm,
  loading,
  type,
}: {
  name: string
  open: boolean
  integrationId: TIntegrationId
  onClose: () => void
  onConfirm: () => void
  loading?: boolean
  type: TIntegration["type"]
}) {
  const { t, langKeys } = useTranslate()

  function handleClose() {
    onClose()
  }

  function handleConfirm() {
    onConfirm()
  }

  return (
    <ConfirmDialog
      title={t(langKeys.integration_dialog_accept_title, { name })}
      confirmLabel={t(langKeys.integrations_dialog_agree_and_continue)}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      loading={loading}
    >
      <DialogBody>
        {getIntegrationDialogBody({ integrationId, name, type, t })}
      </DialogBody>
    </ConfirmDialog>
  )
}

function getIntegrationDialogBody({
  integrationId,
  name,
  type,
  t,
}: {
  integrationId: TIntegrationId
  name: string
  type: TIntegration["type"]
  t: TTranslateFunction
}) {
  if (type === "hvac") {
    return t(langKeys.integration_hvac_accept_body, { name })
  }

  if (type === "pms") {
    return t(langKeys.integration_pms_accept_body)
  }

  if (type === "lock") {
    return t(langKeys.integration_lock_accept_body)
  }

  if (type === "ota") {
    if (integrationId === "airbnb") {
      return t(langKeys.integration_airbnb_accept_body)
    }

    return t(langKeys.integrations_ota_accept_body)
  }

  return ""
}

const DialogBody = styled.div`
  white-space: pre-line;
`
