import styled from "styled-components"

import { ConfiguredIntegrationProgress } from "src/components/Integrations/ConfiguredIntegrationsList/ConfiguredIntegrationProgress"
import { LinkedHomesAlert } from "src/components/Integrations/ConfiguredIntegrationsList/LinkedHomesAlert"
import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import {
  checkBrokenIntegration,
  getConnectedStateColor,
  isExternalLinkIntegration,
  showIntegrationProgressData,
} from "src/data/integrations/logic/integrations"
import { useFetchIntegrationEntities } from "src/data/integrations/queries/integrationEntityQueries"
import { useFetchIntegrationHomeLinks } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ConfiguredIntegrationCard({
  integration,
}: {
  integration: TIntegration
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const isExternal = isExternalLinkIntegration(integration)
  const fetchLinkedIntegrationHomeLinks = useFetchIntegrationHomeLinks(
    {
      integrationId: integration.integration_identifier,
      ownerId: orgId,
      filters: { link_status: "linked" },
    },
    { enabled: !isExternal }
  )

  const fetchBrokenIntegrationHomeLinks = useFetchIntegrationHomeLinks(
    {
      integrationId: integration.integration_identifier,
      ownerId: orgId,
      filters: { link_status: "broken" },
    },
    { enabled: !isExternal }
  )

  const fetchHomesCount = useFetchHomesCount({
    orgId,
  })

  const fetchIntegrationEntities = useFetchIntegrationEntities({
    integration: integration.integration_identifier,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
    options: { enabled: !isExternal },
  })

  const homesCount = fetchHomesCount.data || 0

  const linksCount =
    fetchLinkedIntegrationHomeLinks.data?.paging.total_count || 0

  const loading =
    fetchHomesCount.isLoading ||
    fetchLinkedIntegrationHomeLinks.isInitialLoading ||
    fetchIntegrationEntities.isInitialLoading ||
    fetchBrokenIntegrationHomeLinks.isInitialLoading

  const isBrokenIntegration = checkBrokenIntegration(integration)

  const brokenLinksCount =
    fetchBrokenIntegrationHomeLinks.data?.paging.total_count ?? 0

  const hasBrokenLinks = brokenLinksCount > 0

  const linkedHomesCount = linksCount + brokenLinksCount

  if (loading) {
    return <IntegrationsCardSkeleton />
  }

  return (
    <CardBox padding={spacing.L}>
      <ContentBox>
        <IntegrationIcon icon={integration.icon} />

        <DetailsBox>
          <TopRow>
            <ConnectionBox>
              <MText variant="heading3">{integration.name}</MText>

              <MBanner
                size="small"
                type={getConnectedStateColor({
                  linksCount,
                  isExternal,
                  isBrokenIntegration: isBrokenIntegration,
                  hasBrokenLinks,
                })}
              >
                {isBrokenIntegration
                  ? t(langKeys.integrations_broken_connection)
                  : t(langKeys.integrations_connected)}
              </MBanner>
            </ConnectionBox>

            {showIntegrationProgressData({
              homesCount,
              isExternal,
              isConfigured: integration.configured,
              error: fetchLinkedIntegrationHomeLinks.isError,
            }) && (
              <MText color="tertiary">
                {linkedHomesCount}/{homesCount}{" "}
                {t(langKeys.integrations_homes_linked_text)}
              </MText>
            )}
          </TopRow>

          {isExternal ? (
            <MText variant="body" color="tertiary">
              {t(langKeys.integrations_manage_external_integrations_text, {
                name: integration.name,
              })}
            </MText>
          ) : (
            <ConfiguredIntegrationProgress
              homesCount={homesCount}
              homesLinked={linksCount}
              isBrokenIntegration={isBrokenIntegration}
              brokenLinksCount={brokenLinksCount}
              showProgressBar={integration.configured}
              loading={loading}
            />
          )}
        </DetailsBox>
      </ContentBox>

      {!isExternal && (
        <LinkedHomesAlert
          homesCount={homesCount}
          linksCount={linksCount}
          name={integration.name}
          linkError={fetchLinkedIntegrationHomeLinks.isError}
          isBrokenIntegration={isBrokenIntegration}
          hasBrokenLinks={hasBrokenLinks}
        />
      )}
    </CardBox>
  )
}

function IntegrationsCardSkeleton() {
  return (
    <CardBox padding={spacing.L}>
      <ContentBox>
        <MSkeleton variant="rect" height={56} width={56} />
        <div>
          <TopRow>
            <MSkeleton variant="text" width={150} />
          </TopRow>

          <MSkeleton variant="text" />
        </div>
      </ContentBox>
    </CardBox>
  )
}

const CardBox = styled(MCard)`
  display: grid;
  gap: ${spacing.M};
`

const DetailsBox = styled.div`
  display: grid;
  width: 100%;
  gap: ${spacing.XS};
`

const ContentBox = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
  align-items: center;
  gap: ${spacing.L};
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ConnectionBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing.M};
`
