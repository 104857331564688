import { useFetchFeatureAvailability } from "src/data/featureAvailability/queries/featureAvailabilityQueries"
import { FeatureAvailability } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import {
  TIntegrationId,
  TIntegrationType,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { ErrorService } from "src/utils/ErrorService"
import { logger } from "src/utils/logger"

export type TIntegrationAvailability = {
  ready: boolean
} & Partial<FeatureAvailability> &
  Pick<FeatureAvailability, "available">

export function useIntegrationAvailability({
  integrationId,
  integrationType,
  refresh,
}: {
  integrationId: TIntegrationId
  integrationType: TIntegrationType
  refresh?: boolean
}): TIntegrationAvailability {
  const { org } = useOrganization()
  const fetchFeatureAvailability = useFetchFeatureAvailability({
    organizationId: org.id,
    options: {
      refetchOnMount: refresh ? "always" : true,
    },
  })

  const availability = getIntegrationAvailability({
    integrationId,
    integrationType,
    availabilities: fetchFeatureAvailability.data,
  })

  return {
    ready:
      !fetchFeatureAvailability.isInitialLoading ||
      !!fetchFeatureAvailability.data,
    ...availability,
  }
}

export function getIntegrationAvailability({
  integrationId,
  integrationType,
  availabilities,
}: {
  integrationId: TIntegrationId
  integrationType: TIntegrationType
  availabilities: FeatureAvailability[] | undefined
  refresh?: boolean
}): Partial<FeatureAvailability> & Pick<FeatureAvailability, "available"> {
  if (!availabilities) {
    return { available: false }
  }

  // Checking if the specific integration has any availability rules by
  // looking for availability keys that start with the integration id and
  // suffixed with `_integration`.
  const specificIntegrationAvailability = availabilities?.find(
    (fa) => fa.name === `${integrationId}_integration`
  )

  if (!!specificIntegrationAvailability) {
    return specificIntegrationAvailability
  }

  // If the specific integration does not have any specific availability rules,
  // instead fallback to checking the feature availability array for availability
  // based on the specific type of  integration instead (e.g. `OTA`, `HVAC`, `PMS`, etc.).
  // These are found by looking for the category type suffixed
  // with `_integrations`.
  const integrationTypeAvailability = availabilities?.find(
    (fa) => fa.name === `${integrationType}_integrations`
  )

  if (!!integrationTypeAvailability) {
    return integrationTypeAvailability
  }

  const message = `Could not find matching integration availability for ${integrationId} type: ${integrationType}`
  logger.warn(message)
  ErrorService.captureMessage(message)

  return { available: false }
}
