import { useRef, useState } from "react"
import styled from "styled-components"

import { UpgradeBlockerButton } from "src/components/FeatureBlockers/UpgradeBlockerButton"
import { usePostTrackCsvStayImporterEvent } from "src/data/analytics/queries/csvStayImporterAnalyticsQueries"
import { usePostGuestsCSV } from "src/data/integrations/queries/integrationQueries"
import {
  TPostGuestsCSVError,
  TPostGuestsCSVResponse,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { greyScale, mColors } from "src/ui/colors"
import UpgradeIcon from "src/ui/icons/arrow-up-filled-upgrade.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DragDropArea({
  setUploadStatus,
  featureAvailable = false,
}: {
  setUploadStatus: (status: UploadStatus | undefined) => void
  featureAvailable?: boolean
}) {
  const [message, setFileMessage] = useState<string>()
  const [file, setFilePicked] = useState<File>()
  const inputFile = useRef<HTMLInputElement>(null)
  const { org } = useOrganization()
  const postGuestsCSV = usePostGuestsCSV()

  const postTrackCsvStayImporterInitiatedEvent =
    usePostTrackCsvStayImporterEvent("Stay Import Initiated")

  const postTrackCsvStayImporterFileUploadedEvent =
    usePostTrackCsvStayImporterEvent("Stay Import File Uploaded")

  const postTrackCsvStayImporterCompletedEvent =
    usePostTrackCsvStayImporterEvent("Stay Import Completed")

  function setAndTrackFilePicked(file: File | undefined) {
    setFilePicked(file)
    if (file) {
      postTrackCsvStayImporterFileUploadedEvent.mutate({})
    }
  }

  function dragTargetForFiles(event: React.DragEvent) {
    // Files are only valid drag and drop items
    if (event.dataTransfer.types.includes("Files")) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  function handleDrop(event: React.DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    setUploadStatus(undefined)
    handleFile(
      event.dataTransfer.files,
      setFileMessage,
      setAndTrackFilePicked,
      setUploadStatus
    )
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    event.stopPropagation()
    setUploadStatus(undefined)
    handleFile(
      event.target.files,
      setFileMessage,
      setAndTrackFilePicked,
      setUploadStatus
    )
  }

  function handleBrowseClick(event: React.MouseEvent) {
    event.stopPropagation()
    if (featureAvailable) {
      inputFile.current?.click()
    }
  }

  function handleCancelClick(event: React.MouseEvent) {
    event.stopPropagation()
    if (!postGuestsCSV.isLoading) {
      setAndTrackFilePicked(undefined)
    }
  }

  async function handleImportClick(event: React.MouseEvent) {
    event.stopPropagation()
    if (file && !postGuestsCSV.isLoading) {
      postTrackCsvStayImporterInitiatedEvent.mutate({})
      let text = ""
      try {
        text = await file.text()
      } catch (error) {
        setUploadStatus({ error: { error_key: "file_not_readable" } })
        return
      }
      postGuestsCSV.mutate(
        { orgId: org.id, csvData: text },
        {
          onSettled: (data, error) => {
            if (error) {
              setUploadStatus({ error: error.response?.data })
              postTrackCsvStayImporterCompletedEvent.mutate({
                imported_stays: 0,
                import_errors: 0,
                success: false,
              })
            } else if (data) {
              postTrackCsvStayImporterCompletedEvent.mutate({
                imported_stays: data.guests_imported,
                import_errors: data.errors?.length ?? 0,
                success: true,
              })
              setUploadStatus({
                importedGuests: data.guests_imported,
                rowErrors: data.errors,
              })
            }
          },
        }
      )
    }
  }

  function content() {
    if (!featureAvailable) {
      return <UpgradeBlockContent />
    }
    if (file) {
      return (
        <UploadContent
          fileName={message}
          loading={postGuestsCSV.isLoading}
          onImportClick={handleImportClick}
          onCancelClick={handleCancelClick}
        />
      )
    }
    return <PickFileContent onClick={handleBrowseClick} />
  }

  return (
    <DragDropBox
      enabled={featureAvailable}
      filePicked={!!file}
      onDragEnter={dragTargetForFiles}
      onDragOver={dragTargetForFiles}
      onDrop={handleDrop}
      onClick={() => !file && inputFile.current?.click()}
    >
      <IconBox>{content()}</IconBox>
      <input
        type="file"
        id="file"
        disabled={!featureAvailable}
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleOnChange}
        onClick={(e) => {
          // Makes it possible to upload the same file multiple times.
          e.currentTarget.value = ""
        }}
      />
    </DragDropBox>
  )
}

function UploadContent({
  fileName,
  loading,
  onImportClick,
  onCancelClick,
}: {
  fileName: string | undefined
  loading: boolean
  onCancelClick: (e: React.MouseEvent) => void
  onImportClick: (e: React.MouseEvent) => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <FilePickedContentBox>
      <MText variant="body">
        {t(langKeys.stay_importer_ready_for_import, { fileName })}
      </MText>
      <FilePickedContentButtons>
        <UploadButton onClick={onImportClick} loading={loading} size="small">
          {t(langKeys.stay_importer_import_button)}
        </UploadButton>
        <MButton variant="minimal" onClick={onCancelClick}>
          {t(langKeys.cancel)}
        </MButton>
      </FilePickedContentButtons>
    </FilePickedContentBox>
  )
}

function PickFileContent({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MText variant="subtitle" color="primary">
      {t(langKeys.stay_importer_drag_instruction)}{" "}
      <MButton variant="minimal" onClick={onClick}>
        {t(langKeys.stay_importer_browse)}
      </MButton>
    </MText>
  )
}

function UpgradeBlockContent() {
  const { t, langKeys } = useTranslate()

  return (
    <UpgradeBlockerBox>
      <UpgradeIcon />
      <MText variant="subtitleS">
        {t(langKeys.subscription_upgrade_blocker_title)}
      </MText>
      <UpgradeBlockerButton
        size="small"
        context="subscription_required"
        plan="pro"
      />
    </UpgradeBlockerBox>
  )
}

type FilePickerErrorKey =
  | "file_too_large"
  | "no_file"
  | "multiple_files"
  | "file_not_readable"
export interface UploadStatus {
  importedGuests?: TPostGuestsCSVResponse["guests_imported"]
  rowErrors?: TPostGuestsCSVResponse["errors"]
  error?: TPostGuestsCSVError | { error_key: FilePickerErrorKey }
}

// 5mb
const FILE_SIZE_LIMIT = 5000000

function handleFile(
  files: FileList | null,
  setFileName: (message: string) => void,
  setFilePicked: (file: File) => void,
  setUploadStatus: (status: UploadStatus | undefined) => void
) {
  if (!files || !files[0]) {
    setUploadStatus({ error: { error_key: "no_file" } })
    return
  }

  if (files.length > 1) {
    setUploadStatus({ error: { error_key: "multiple_files" } })
    return
  }

  const file = files[0]

  if (file.size > FILE_SIZE_LIMIT) {
    setUploadStatus({ error: { error_key: "file_too_large" } })
    return
  }
  setFileName(`${file.name}`)
  setFilePicked(file)
}

const UploadButton = styled(MButtonLegacy)`
  z-index: 99;
`

const IconBox = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`

const DragDropBox = styled.div<{ enabled: boolean; filePicked: boolean }>`
  display: flex;
  justify-content: center;
  ${({ enabled, filePicked }) =>
    !enabled ? "cursor: not-allowed;" : !filePicked ? "cursor: pointer;" : ""}
  align-items: center;
  height: 20vh;
  max-height: 350px;
  border: 2px dashed ${mColors.divider};
  background: ${greyScale[50]}90;
  border-radius: 6px;
`

const FilePickedContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.M};
`

const FilePickedContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.XS};
`

const UpgradeBlockerBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
