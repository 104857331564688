import { useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { DateFormatDialog } from "./DateFormatDialog"
import { PhoneFormatDialog } from "./PhoneFormatDialog"

export function FileRequirementsSection() {
  const { t, langKeys } = useTranslate()
  const [dateFormatDialogOpen, setDateFormatDialogOpen] = useState(false)
  const [phoneFormatDialogOpen, setPhoneFormatDialogOpen] = useState(false)

  const headers = [
    t(langKeys.stay_importer_supported_columns_header),
    t(langKeys.stay_importer_supported_columns_description),
    t(langKeys.stay_importer_supported_columns_alternatives),
  ].map((h, i) => (
    <MText key={i} variant="subtitleS" color="tertiary">
      {h}
    </MText>
  ))
  const rows = [
    <Section
      key="rental-unit-name"
      header="Rental unit name"
      required
      alternatives={[
        "Property",
        "Property name",
        "Unit name",
        "Home name",
        "Rental unit",
        "Unit code",
      ]}
    >
      <MText variant="body">
        {t(langKeys.stay_importer_format_rental_unit_name)}
      </MText>
    </Section>,
    <Section
      key="full-name"
      header="Full name*"
      required
      alternatives={["Tenant name", "Stay name", "Guest name", "Name"]}
    >
      <ReactMarkdown>
        {t(langKeys.stay_importer_format_full_name_md)}
      </ReactMarkdown>
    </Section>,
    <Section
      key="first-name"
      header="First name*"
      required
      alternatives={["Tenant first name"]}
    >
      <ReactMarkdown>
        {t(langKeys.stay_importer_format_first_name_md)}
      </ReactMarkdown>
    </Section>,
    <Section
      key="last-name"
      header="Last name*"
      required
      alternatives={["Tenant last name"]}
    >
      <ReactMarkdown>
        {t(langKeys.stay_importer_format_last_name_md)}
      </ReactMarkdown>
    </Section>,
    <Section
      key="start-date"
      header="Start date"
      required
      alternatives={["Arrive", "Check in", "Arrival"]}
    >
      {t(langKeys.stay_importer_format_start_date)}{" "}
      <MButton variant="minimal" onClick={() => setDateFormatDialogOpen(true)}>
        {t(langKeys.learn_more)}
      </MButton>
      .
    </Section>,
    <Section
      key="end-date"
      header="End date"
      required
      alternatives={["Depart", "Check out"]}
    >
      {t(langKeys.stay_importer_format_end_date)}{" "}
      <MButton variant="minimal" onClick={() => setDateFormatDialogOpen(true)}>
        {t(langKeys.learn_more)}
      </MButton>
      .
    </Section>,
    <Section
      key="phone-number"
      header="Phone number"
      required
      alternatives={[
        "Phone",
        "Tenant number",
        "Tenant phone",
        "Stay mobile",
        "Telephone",
        "Guest mobile",
      ]}
    >
      {t(langKeys.stay_importer_format_phone_number)}{" "}
      <MButton variant="minimal" onClick={() => setPhoneFormatDialogOpen(true)}>
        {t(langKeys.learn_more)}
      </MButton>
      .
    </Section>,
    <Section
      key="email"
      header="Email"
      required
      alternatives={["Guest email", "Stay email"]}
    >
      {t(langKeys.stay_importer_format_email)}
    </Section>,
  ]

  return (
    <Box>
      <ExpandableSection title="File Requirements">
        <DateFormatDialog
          open={dateFormatDialogOpen}
          onClose={() => setDateFormatDialogOpen(false)}
        />
        <PhoneFormatDialog
          open={phoneFormatDialogOpen}
          onClose={() => setPhoneFormatDialogOpen(false)}
        />
        <Content>
          <MText variant="body">
            {t(langKeys.stay_importer_file_requirements_subtitle)}
          </MText>

          <MText variant="subtitleS" color="secondary">
            {t(langKeys.stay_importer_supported_columns)}
          </MText>
          <GridTable
            templateColumns="1fr 3fr auto"
            header={headers}
            rows={rows}
            useFallbackResponsiveMode
          />
        </Content>
      </ExpandableSection>
    </Box>
  )
}

const Box = styled.div`
  border: 1px solid ${mColors.divider};
  border-radius: ${spacing.M};
  padding: ${spacing.L};
`

const Content = styled.div`
  display: grid;
  gap: ${spacing.XL};
  padding-block: ${spacing.M};
`

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS2};
  align-items: flex-start;
  justify-content: flex-start;
`

const Description = styled.div`
  display: block;

  p {
    padding: ${spacing.XS2} 0;
    margin: ${spacing.XS2} 0;
  }

  p:first-child {
    margin-top: 0;
    padding-top: 0;
  }
`

function Section({
  header,
  children,
  alternatives,
  required,
}: {
  header: string
  children: React.ReactNode
  alternatives: string[]
  required: boolean
}) {
  const { t, langKeys } = useTranslate()
  return (
    <>
      <HeaderColumn>
        <MText variant="subtitleS">{header}</MText>
        {required && (
          <RequiredBannerBox>
            <MBanner type="info" size="small">
              {t(langKeys.stay_importer_header_required)}
            </MBanner>
          </RequiredBannerBox>
        )}
      </HeaderColumn>
      <Description>
        <MText variant="body">{children}</MText>
      </Description>
      <Description>
        <AlternativeList>
          {alternatives.map((a) => (
            <li key={a}>{a}</li>
          ))}
        </AlternativeList>
      </Description>
    </>
  )
}

const RequiredBannerBox = styled.div`
  display: flex;
  align-items: center;
`

const AlternativeList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: ${spacing.M};
`
