import { usePostIntegrationUnlinkInit } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { useDeleteIntegrationLink } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"

export function UnlinkDialog({
  integration,
  open,
  homeName,
  entityName,
  linkId,
  onClose,
}: {
  integration: TIntegration
  open: boolean
  homeName: string
  entityName: string
  linkId: string
  onClose: () => void
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const deleteIntegrationLink = useDeleteIntegrationLink()

  const postIntegrationUnlinkHomeInit = usePostIntegrationUnlinkInit()

  const integrationId = integration?.integration_identifier

  const unlinkKey =
    integrationId === "airbnb"
      ? langKeys.integrations_link_remove_confirm_airbnb
      : langKeys.integrations_link_remove_confirm

  const handleUnlinkClick = async () => {
    postIntegrationUnlinkHomeInit.mutate({
      integration_name: integration?.name,
    })
    deleteIntegrationLink.mutate(
      {
        integration: integrationId,
        orgId,
        linkId: linkId,
        ownerType: OwnerType.ORGANIZATION,
      },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )

    return
  }

  const description = (
    <>
      {t(unlinkKey, { minut_home: homeName, integration_entity: entityName })}
      {integration.type === "lock" && (
        <p>Guests will no longer receive an access code from this lock.</p>
      )}

      {integration.type === "pms" && (
        <p>{t(langKeys.integrations_link_remove_confirm_disclaimer)}</p>
      )}
    </>
  )

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t(langKeys.integrations_link_remove)}
      description={description}
      loading={deleteIntegrationLink.isLoading}
      onConfirm={handleUnlinkClick}
      confirmLabel={t(langKeys.unlink)}
      aria-labelledby="link-title"
      aria-describedby=""
      error={
        deleteIntegrationLink.isError && t(langKeys.failed_general_error_title)
      }
    />
  )
}
