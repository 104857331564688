import styled from "styled-components"

import { ConfiguredIntegrationCard } from "src/components/Integrations/ConfiguredIntegrationsList/ConfiguredIntegrationCard"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export function ConfiguredIntegrationsList({
  configuredIntegrations,
}: {
  configuredIntegrations: TIntegration[]
}) {
  if (configuredIntegrations.length <= 0) {
    return null
  }

  return (
    <Box>
      {configuredIntegrations.map((i) => {
        const linkTo = Routes.OrgIntegration.location(
          i.integration_identifier
        ).pathname

        return (
          <InternalLink
            to={linkTo}
            key={i.integration_identifier}
            underline="never"
          >
            <ConfiguredIntegrationCard
              key={i.integration_identifier}
              integration={i}
            />
          </InternalLink>
        )
      })}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
`
