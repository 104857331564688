import { useState } from "react"
import styled from "styled-components"

import { findUpdateAvailableConnection } from "src/data/integrations/logic/integrations"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import LightBulbOn from "src/ui/icons/lightbulb-on.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

import { UpdateAirbnbIntegrationDialog } from "./UpdateAirbnbIntegrationDialog"

export function UpdateAirbnbIntegrationBanner({
  integration,
}: {
  integration: TIntegration
}) {
  const { t, langKeys } = useTranslate()

  const [showDialog, setShowDialog] = useState(false)

  const isAirbnbIntegration = integration.integration_identifier === "airbnb"
  const updateAvailableConnection = findUpdateAvailableConnection(integration)

  const showBanner = isAirbnbIntegration && !!updateAvailableConnection

  if (!showBanner) return null

  return (
    <UpgradeDisclaimerBox>
      {showDialog && (
        <UpdateAirbnbIntegrationDialog
          integration={integration}
          accountId={updateAvailableConnection.account_id}
          onClose={() => setShowDialog(false)}
        />
      )}
      <MBanner
        type="info"
        icon={<LightBulbOn width="24" height="24" />}
        title={t(langKeys.integration_airbnb_update_banner_header)}
        buttons={[
          {
            label: t(langKeys.integration_airbnb_update_button),
            onClick: () => setShowDialog(true),
          },
        ]}
        fullWidth
      >
        {t(langKeys.integration_airbnb_update_banner_body)}
      </MBanner>
    </UpgradeDisclaimerBox>
  )
}

const UpgradeDisclaimerBox = styled.div`
  max-width: 80ch;
  margin-right: ${spacing.M};
`
