import { useState } from "react"
import styled from "styled-components"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import {
  DragDropArea,
  UploadStatus,
} from "src/components/Integrations/StaticIntegrations/GuestImport/DragDropArea"
import { FileRequirementsSection } from "src/components/Integrations/StaticIntegrations/GuestImport/FileRequirementsSection"
import { usePostTrackCsvStayImporterEvent } from "src/data/analytics/queries/csvStayImporterAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { BackButton } from "src/ui/BackButton/BackButton"
import { mColors } from "src/ui/colors"
import ErrorLogIcon from "src/ui/icons/eventIcons/error-log.svg"
import OkLogIcon from "src/ui/icons/eventIcons/ok-log.svg"
import { DefaultBox } from "src/ui/Layout/DefaultBox"
import { MainView } from "src/ui/Layout/MainView"
import { PageSubtitle } from "src/ui/Layout/PageSubtitle"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { InternalLink } from "src/ui/Link/InternalLink"
import { BetaFeaturePill } from "src/ui/MBadge/BetaFeaturePill"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import GuestImportIcon from "./guest-import.svg"

const MAX_AMOUNT_OF_STAYS = 2000

export function GuestImport() {
  const { t, langKeys } = useTranslate()
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>()
  const { available } = useFeatureAvailability({
    feature: "csv_guest_import",
  })

  const postTrackCsvStayImporterViewedEvent = usePostTrackCsvStayImporterEvent(
    "Stay Importer Viewed"
  )

  useEffectOnce(() => {
    postTrackCsvStayImporterViewedEvent.mutate({})
  })

  const totalStays =
    (uploadStatus?.importedGuests ?? 0) + (uploadStatus?.rowErrors?.length ?? 0)

  function formatRowError(error: { error: string; error_key: string }) {
    // GXP-702: Type the error keys when BE has support for it
    if (error.error_key === "CSV_PARSE_ERROR") {
      return t(langKeys.stay_importer_error_incorrectly_formatted)
    }

    // GXP-702: When more keys are typed we could use them and have the copy on the FE instead of relying on BE error messages
    return error.error
  }

  function getErrorTitle(error: UploadStatus["error"]) {
    if (error?.error_key === "multiple_files") {
      return t(langKeys.stay_importer_error_multiple_files_title)
    }
    return t(langKeys.stay_importer_error_generic_title)
  }

  function getErrorDescription(error: UploadStatus["error"]) {
    if (error?.error_key === "auth_error") {
      return error.message
    } else if (
      error?.error_key === "too_many_rows" ||
      error?.error_key === "file_too_large"
    ) {
      return t(langKeys.stay_importer_error_file_too_large, {
        max: MAX_AMOUNT_OF_STAYS,
      })
    } else if (
      error?.error_key === "file_not_readable" ||
      error?.error_key === "no_file"
    ) {
      return t(langKeys.stay_importer_error_file_not_readable)
    } else if (error?.error_key === "multiple_files") {
      return t(langKeys.stay_importer_error_multiple_files_content)
    }
    return t(langKeys.stay_importer_error_generic_content)
  }

  return (
    <div>
      <BackButton to={Routes.OrgIntegrations.location()}>
        {t(langKeys.back)}
      </BackButton>

      <MainView
        metaTitle={t(langKeys.stay_importer_title)}
        titleBarProps={{
          showBottomBorder: true,
        }}
        title={
          <div>
            <Top>
              <IntegrationIcon
                iconComponent={(size) => (
                  <GuestImportIcon width={size} height={size} />
                )}
                size={65}
              />
              <PageTitle>{t(langKeys.stay_importer_title)}</PageTitle>
              {!available && (
                <UpgradeIconButton
                  context="subscription_required"
                  requiredPlan="pro"
                />
              )}
              <BetaFeaturePillBox>
                <BetaFeaturePill />
              </BetaFeaturePillBox>
            </Top>
            <PageSubtitle>{t(langKeys.stay_importer_subtitle)}</PageSubtitle>
          </div>
        }
      >
        <DefaultBox>
          <Content>
            {!!uploadStatus?.importedGuests &&
              uploadStatus?.rowErrors?.length === 0 && (
                <MBanner
                  type="good"
                  icon={
                    <OkLogIcon
                      width={24}
                      height={24}
                      color={mColors.systemGoodDark}
                    />
                  }
                  title={t(langKeys.stay_importer_import_success, {
                    count: uploadStatus.importedGuests,
                  })}
                >
                  {t(langKeys.stay_importer_import_success_content)}{" "}
                  <InternalLink to={Routes.Homes.location()}>
                    {t(langKeys.stay_importer_import_success_link)}
                  </InternalLink>
                </MBanner>
              )}

            {uploadStatus?.error && (
              <MBanner
                icon={
                  <ErrorLogIcon
                    width={24}
                    height={24}
                    color={mColors.systemErrorDark}
                  />
                }
                type="error"
                title={getErrorTitle(uploadStatus.error)}
              >
                {getErrorDescription(uploadStatus.error)}
              </MBanner>
            )}

            {uploadStatus?.rowErrors && !!uploadStatus.rowErrors.length && (
              <MBanner
                type="error"
                icon={
                  <ErrorLogIcon
                    width={24}
                    height={24}
                    color={mColors.systemErrorDark}
                  />
                }
                title={t(langKeys.stay_importer_partial_fail_title, {
                  failed: uploadStatus.rowErrors.length,
                  total: totalStays,
                })}
              >
                <ErrorList>
                  {uploadStatus.rowErrors.map((row) => (
                    <ErrorListItem key={row.row}>
                      {t(langKeys.stay_importer_error_line, {
                        line: row.row,
                        error: formatRowError(row),
                      })}
                    </ErrorListItem>
                  ))}
                </ErrorList>
              </MBanner>
            )}

            <div>
              <MText variant="heading3">
                {t(langKeys.stay_importer_instructions_title)}
              </MText>
              <MText variant="bodyS" color="secondary">
                {t(langKeys.stay_importer_instructions)}
              </MText>
            </div>

            <StyledDragDropArea
              setUploadStatus={setUploadStatus}
              featureAvailable={available}
            />

            <FileRequirementsSection />
          </Content>
        </DefaultBox>
      </MainView>
    </div>
  )
}

const Content = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding-bottom: ${spacing.S};
`

const Top = styled.div`
  display: flex;
  gap: ${spacing.XS};
  padding-bottom: ${spacing.S};
  align-items: center;
`

const StyledDragDropArea = styled(DragDropArea)`
  margin: ${spacing.XS} 0;
`

const ErrorList = styled.ul``

const ErrorListItem = styled.li`
  margin-bottom: ${spacing.S};
  padding-right: ${spacing.S};
`

const BetaFeaturePillBox = styled.div`
  padding-bottom: ${spacing.XS2};
`
