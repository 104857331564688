import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PhoneFormatDialog({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MDialog
      open={open}
      title={t(langKeys.stay_importer_format_phone_dialog_title)}
      onClose={onClose}
      hideClose
      onConfirm={onClose}
      confirmLabel={t(langKeys.close)}
    >
      <PhoneFormatBox>
        <MText>
          <ReactMarkdown>
            {t(langKeys.stay_importer_format_phone_dialog_content_md)}
          </ReactMarkdown>
        </MText>
      </PhoneFormatBox>
    </MDialog>
  )
}

const PhoneFormatBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`
