import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { semanticEmergency } from "src/ui/colors"
import InfoIcon from "src/ui/icons/important-filled.svg"
import { spacing } from "src/ui/spacing"

export function LinkedHomesAlert({
  homesCount,
  linksCount,
  name,
  linkError,
  isBrokenIntegration,
  hasBrokenLinks,
}: {
  homesCount: number
  linksCount: number
  name: string
  linkError: boolean
  isBrokenIntegration: boolean
  hasBrokenLinks: boolean
}) {
  const { t, langKeys } = useTranslate()

  const hasHomes = homesCount >= 1

  if (isBrokenIntegration) {
    return (
      <AlertBox>
        <InfoIcon width={18} height={18} fill={semanticEmergency} />
        {t(langKeys.integrations_connection_broken_alert_text)}
      </AlertBox>
    )
  }

  if (hasBrokenLinks) {
    return (
      <AlertBox>
        <InfoIcon width={18} height={18} fill={semanticEmergency} />
        {t(langKeys.integrations_home_links_broken_alert_text)}
      </AlertBox>
    )
  }

  if (linkError) {
    return (
      <AlertBox>
        <InfoIcon width={18} height={18} />
        {t(langKeys.failed_general_error_try_refreshing_page)}
      </AlertBox>
    )
  }

  if (linksCount >= 1) {
    return null
  }

  return (
    <AlertBox>
      <InfoIcon width={18} height={18} fill={semanticEmergency} />

      {hasHomes ? (
        t(langKeys.integrations_homes_not_linked_alert_text)
      ) : (
        <span>
          {t(langKeys.integrations_link_homes_to_sync_data_alert_text, {
            name,
          })}{" "}
          {t(langKeys.integrations_create_homes_to_get_started_link)}
        </span>
      )}
    </AlertBox>
  )
}

const AlertBox = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
  align-items: center;
  gap: ${spacing.XS};
  color: ${semanticEmergency};
`
