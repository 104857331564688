import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { useTrackIntegrationViewedEvent } from "src/data/integrations/hooks/useSendIntegrationEvents"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import { DefaultBox } from "src/ui/Layout/DefaultBox"
import { MLinkStyle, MLinkStyleProps } from "src/ui/Link/MLink"
import { MText } from "src/ui/MText"

export function AboutIntegrationTab({
  integration,
  tabId,
  description,
  richDescription,
}: {
  integration: TIntegration
  tabId: string
  description: string
  richDescription: string
}) {
  useTrackIntegrationViewedEvent({
    integration,
    tabId,
  })

  return (
    <DefaultBox size="small">
      <TextStyles>
        {richDescription ? (
          <ReactMarkdown linkTarget="_blank">{richDescription}</ReactMarkdown>
        ) : (
          <div>{description}</div>
        )}
      </TextStyles>
    </DefaultBox>
  )
}

const TextStyles = styled(MText)<MLinkStyleProps>`
  // Apply link styles to anchor tags that exist in the rich description
  a {
    ${MLinkStyle};
  }
`
