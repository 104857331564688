import { TIntegrationId } from "src/data/integrations/types/integrationTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"

export function ConfirmDisconnectDialog({
  name,
  integrationId,
  open,
  onClose,
  onConfirm,
  loading,
}: {
  name: string
  integrationId: TIntegrationId
  open: boolean
  loading: boolean
  onClose: () => void
  onConfirm: () => void
}) {
  const { t } = useTranslate()

  function handleClose() {
    onClose()
  }

  function handleConfirm() {
    onConfirm()
  }

  return (
    <ConfirmDialog
      title={`${t(langKeys.integrations_disconnect_confirm_title, {
        integration: name,
      })}`}
      description={getDialogBody({ t, integrationId })}
      confirmButtonProps={{
        color: "destructive",
      }}
      confirmLabel={t(langKeys.disconnect)}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      loading={loading}
      autoFocus={false}
    />
  )
}

function getDialogBody({
  t,
  integrationId,
}: {
  t: TTranslateFunction
  integrationId: TIntegrationId
}) {
  if (integrationId === "airbnb") {
    return t(langKeys.integrations_disconnect_confirm_body_airbnb)
  }

  return t(langKeys.integrations_disconnect_confirm_body)
}
