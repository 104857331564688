import { EmptyState } from "src/components/EmptyState/EmptyState"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import ApartmentIcon from "src/ui/icons/apartment-three-buildings.svg"

export function NoHomes({ body }: { body: string }) {
  const { t } = useTranslate()

  return (
    <EmptyState
      icon={<ApartmentIcon />}
      title={t(langKeys.organization_has_no_homes)}
      body={body}
    />
  )
}

export function NoHomesOnFilter() {
  const { t } = useTranslate()

  return (
    <EmptyState
      icon={<ApartmentIcon />}
      title={t(langKeys.search_nothing_found)}
    />
  )
}
