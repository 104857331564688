import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"

const supportedDateFormats = [
  "YYYY-MM-DD",
  "YYYY-MM-DD HH:MM",
  "YYYY-MM-DD HH:MM:SS",
  "YYYY-MM-DD:HH:MM",
  "YYYY-MM-DD:HH:MM:SS",
  "MM-DD-YYYY",
  "MM-DD-YYYY HH:MM",
  "MM-DD-YYYY HH:MM:SS",
  "MM/DD/YYYY",
  "MM/DD/YYYY HH:MM",
  "MM/DD/YYYY HH:MM:SS",
]

export function DateFormatDialog({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MDialog
      open={open}
      title={t(langKeys.stay_importer_date_format_dialog_title)}
      onClose={onClose}
      hideClose
      onConfirm={onClose}
      confirmLabel={t(langKeys.close)}
    >
      <MText>{t(langKeys.stay_importer_date_format_dialog_subtitle)}</MText>
      <ul>
        {supportedDateFormats.map((d) => (
          <li key={d}>
            <MText>{d}</MText>
          </li>
        ))}
      </ul>
    </MDialog>
  )
}
