import { TntegrationAuthCredential } from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ConfirmDeleteCredentialDialog({
  nbrLinks,
  showConfirmDelete,
  onClose,
  handleDeleteCredential,
  loading,
  credential,
}: {
  credential: TntegrationAuthCredential
  nbrLinks: number | undefined
  showConfirmDelete: boolean
  onClose: () => void
  handleDeleteCredential: () => void
  loading: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <ConfirmDialog
      title={`${t(langKeys.integrations_disconnect_account)}?`}
      confirmLabel={t(langKeys.integrations_disconnect_account)}
      confirmButtonProps={{
        color: "destructive",
      }}
      open={showConfirmDelete}
      onClose={onClose}
      onConfirm={handleDeleteCredential}
      loading={loading}
    >
      <div>
        <MText variant="subtitle">
          {t(langKeys.integrations_account_name)}:{" "}
          <MText variant="body" as="span">
            {credential.account_name}
          </MText>
        </MText>
        <MText variant="subtitle" marginBottom={spacing.S}>
          {t(langKeys.email)}:{" "}
          <MText variant="body" as="span">
            {credential.email}
          </MText>
        </MText>
        <MText variant="body" marginBottom={spacing.S}>
          {t(langKeys.integrations_disconnect_account_and_unlink_description, {
            count: nbrLinks || 0,
          })}
        </MText>
      </div>
    </ConfirmDialog>
  )
}
