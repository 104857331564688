import styled from "styled-components"

import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { ConfiguredIntegrationProgress } from "src/components/Integrations/ConfiguredIntegrationsList/ConfiguredIntegrationProgress"
import { IntegrationAlert } from "src/components/Integrations/Integration/IntegrationAlert"
import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import { TIntegrationAvailability } from "src/data/featureAvailability/logic/useIntegrationAvailability"
import {
  checkBrokenIntegration,
  getConnectedStateColor,
  isExternalLinkIntegration,
  showIntegrationProgressData,
} from "src/data/integrations/logic/integrations"
import { useFetchIntegrationEntities } from "src/data/integrations/queries/integrationEntityQueries"
import { useFetchIntegrationHomeLinks } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import ChainIcon from "src/ui/icons/chain.svg"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { MBanner, TAlert } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IntegrationDetailsHeader({
  integration,
  integrationsAvailability,
}: {
  integration: TIntegration
  integrationsAvailability: TIntegrationAvailability
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const isExternal = isExternalLinkIntegration(integration)

  const isBrokenIntegration = checkBrokenIntegration(integration)

  const hasActiveEntity = !!integration.connection_status?.some(
    (i) => i.status === "active"
  )

  const fetchLinkedIntegrationHomeLinks = useFetchIntegrationHomeLinks(
    {
      integrationId: integration.integration_identifier,
      ownerId: orgId,
      ownerType: OwnerType.ORGANIZATION,
      filters: { link_status: "linked" },
    },
    {
      enabled: integration.configured && !isExternal,
    }
  )

  const fetchBrokenIntegrationHomeLinks = useFetchIntegrationHomeLinks(
    {
      integrationId: integration.integration_identifier,
      ownerId: orgId,
      ownerType: OwnerType.ORGANIZATION,
      filters: { link_status: "broken", limit: 1 },
    },
    {
      enabled: integration.configured && !isExternal,
    }
  )

  const fetchHomesCount = useFetchHomesCount({
    orgId,
  })

  const fetchIntegrationEntities = useFetchIntegrationEntities({
    integration: integration.integration_identifier,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
    options: {
      enabled: !isExternal && hasActiveEntity && !isBrokenIntegration,
    },
  })
  const integrationEntities = fetchIntegrationEntities.data?.entities ?? []

  const linksCount =
    fetchLinkedIntegrationHomeLinks.data?.paging.total_count || 0

  const homesCount = fetchHomesCount.data || 0

  const error =
    fetchLinkedIntegrationHomeLinks.isError ||
    fetchHomesCount.isError ||
    fetchIntegrationEntities.isError

  const loading =
    fetchLinkedIntegrationHomeLinks.isInitialLoading ||
    fetchHomesCount.isLoading ||
    fetchIntegrationEntities.isInitialLoading ||
    fetchBrokenIntegrationHomeLinks.isInitialLoading

  const shouldShowIntegrationProgressData = showIntegrationProgressData({
    isExternal,
    homesCount,
    isConfigured: integration.configured,
    error: fetchLinkedIntegrationHomeLinks.isError,
  })

  const brokenLinksCount =
    fetchBrokenIntegrationHomeLinks.data?.paging.total_count ?? 0

  const linkedHomesCount = linksCount + brokenLinksCount

  return (
    <Layout>
      <Box>
        {integration.icon ? (
          <IntegrationIcon icon={integration.icon} size={90} />
        ) : (
          <ChainIcon width={90} height={90} />
        )}

        <Details>
          <ConnectionStatus>
            <TitleBox>
              <PageTitle>
                <FeatureBlockerText
                  isFeatureAvailable={integrationsAvailability.available}
                  requiredPlan={
                    integrationsAvailability.required_plan_types?.[0]
                  }
                  hidden={!integrationsAvailability.ready}
                >
                  {integration.name}
                </FeatureBlockerText>
              </PageTitle>

              <ConnectionStatusAlert
                isBrokenIntegration={isBrokenIntegration}
                loading={loading}
                configured={integration.configured}
                alertType={getConnectedStateColor({
                  linksCount,
                  isExternal,
                  isBrokenIntegration: isBrokenIntegration,
                  hasBrokenLinks: brokenLinksCount > 0,
                })}
              />
            </TitleBox>

            {shouldShowIntegrationProgressData && (
              <MText variant="body" color="tertiary">
                {linkedHomesCount}/{homesCount}{" "}
                {t(langKeys.integrations_homes_linked_text)}
              </MText>
            )}
          </ConnectionStatus>

          <ConfiguredIntegrationProgress
            homesLinked={linksCount}
            homesCount={homesCount}
            isBrokenIntegration={isBrokenIntegration}
            loading={loading}
            showProgressBar={shouldShowIntegrationProgressData}
            brokenLinksCount={brokenLinksCount}
          />
        </Details>
      </Box>

      {!loading && integration.configured && (
        <IntegrationAlert
          homeLinks={fetchLinkedIntegrationHomeLinks.data?.homes ?? []}
          linksCount={linksCount}
          homesCount={homesCount}
          integration={integration}
          integrationEntities={integrationEntities}
          hasBrokenLinks={brokenLinksCount > 0}
          error={error}
        />
      )}
    </Layout>
  )
}

export function IntegrationDetailsSkeleton() {
  return (
    <Box>
      <MSkeleton variant="rect" height={90} width={90} />

      <div>
        <ConnectionStatus>
          <TitleBox>
            <MSkeleton width={150} height={60} />
          </TitleBox>
        </ConnectionStatus>

        <MSkeleton width={800} height={30} />
      </div>
    </Box>
  )
}

function ConnectionStatusAlert({
  isBrokenIntegration,
  loading,
  alertType,
  configured,
}: {
  isBrokenIntegration: boolean
  loading: boolean
  alertType: TAlert
  configured: boolean
}) {
  const { t, langKeys } = useTranslate()

  if (loading) {
    return (
      <MSkeleton width={120} height={30} style={{ borderRadius: "12px" }} />
    )
  }

  if (!configured) {
    return null
  }

  return (
    <MBanner size="small" type={alertType}>
      {isBrokenIntegration
        ? t(langKeys.integrations_broken_connection)
        : t(langKeys.integrations_connected)}
    </MBanner>
  )
}

const Layout = styled.div`
  display: grid;
  gap: ${spacing.L};
  width: 100%;
  margin-bottom: ${spacing.S};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.L};
`

const Details = styled.div`
  width: 100%;
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.L};
  flex: 1;
  margin-bottom: ${spacing.M};
`

const ConnectionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.L};
`
